<template>
  <div class="main">
    <el-descriptions
      title="小程序基本信息"
      :column="1"
      :size="size"
      border
      v-if="settings.appid && settings.accountBasicInfo.errcode !== 0"
    >
      <el-descriptions-item>
        <template #label>
          <div>提示</div>
        </template>
        请登录微信小程序后台https://mp.weixin.qq.com/，进入“设置 > 第三方设置 > 第三方平台授权管理 > 管理授权 ”，选中所有授权权限
      </el-descriptions-item>
    </el-descriptions>
    <el-descriptions
      title="小程序基本信息"
      :column="3"
      :size="size"
      border
      v-if="settings.appid && settings.accountBasicInfo.errcode === 0"
    >
      <el-descriptions-item>
        <template #label>
          <div>appid</div>
        </template>
        {{settings.appid}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          <div>主体名称</div>
        </template>
        {{settings.accountBasicInfo.principal_name}}(营业执照：{{settings.accountBasicInfo.credential}})
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          <div>小程序名称</div>
        </template>
        {{settings.accountBasicInfo.nickname_info.nickname}}<el-button @click="showNicknameDialog()" size="small">修改</el-button>  (本月剩余修改次数：{{settings.accountBasicInfo.nickname_info.modify_quota - settings.accountBasicInfo.nickname_info.modify_used_count}})
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          <div>功能介绍</div>
        </template>
        {{settings.accountBasicInfo.signature_info.signature}}<el-button @click="showSignatureDialog()" size="small">修改</el-button>  (本月剩余修改次数：{{settings.accountBasicInfo.signature_info.modify_quota - settings.accountBasicInfo.signature_info.modify_used_count}})
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          <div>头像</div>
        </template>
        <img :src="settings.accountBasicInfo.head_image_info.head_image_url" style="width: 100px; height: 100px;" />
        <el-upload action="" :http-request="uploadHeadimg" :show-file-list="false">
          <el-button size="small" type="default">上传头像</el-button>
        </el-upload>  (本月剩余修改次数：{{settings.accountBasicInfo.head_image_info.modify_quota - settings.accountBasicInfo.head_image_info.modify_used_count}})
      </el-descriptions-item>
    </el-descriptions>
    <el-descriptions
      title="经营类目"
      :column="1"
      border
      v-if="settings.appid"
    >
      <template #extra>
        <el-button @click="showAddCategoryDialog()">增加类目</el-button>
      </template>
      <el-descriptions-item v-if="settings.category">
        <template #label>
          <div>概况</div>
        </template>
        最多可设置{{settings.category.category_limit}}个类目，本月还可以添加{{settings.category.quota}}个类目
      </el-descriptions-item>
      <el-descriptions-item v-for="(category, index) in settings.category.categories" :key="index">
        <template #label>
          <div>类目名</div>
        </template>
        {{category.first_name || ''}}{{'/' + category.second_name || ''}}，状态：
        {{category.audit_status === 1 ? '审核中' : ''}}
        {{category.audit_status === 2 ? '审核不通过，原因：' + category.audit_reason : ''}}
        {{category.audit_status === 3 ? '审核通过' : ''}}
        <el-button @click="delCategory(category.first, category.second)" size="small">删除</el-button>
      </el-descriptions-item>
    </el-descriptions>
    <el-descriptions
      title="微信支付配置"
      :column="3"
      :size="size"
      border
      v-if="settings.appid"
    >
      <template #extra>
        <el-button @click="showMiniSettingDialog()">修改</el-button>
      </template>
      <el-descriptions-item>
        <template #label>
          <div>商户号</div>
        </template>
        {{settings.mchId || '--'}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          <div>支付通道</div>
        </template>
        {{settings.isPlatformPayment ? '服务商通道' : '商户通道'}}
      </el-descriptions-item>
      <el-descriptions-item v-if="!settings.isPlatformPayment">
        <template #label>
          <div>密钥</div>
        </template>
        {{settings.partnerKey || '--'}}
      </el-descriptions-item>
      <el-descriptions-item v-if="!settings.isPlatformPayment">
        <template #label>
          <div>证书{{settings.hasCertFile ? '（已上传）' : ''}}</div>
        </template>
        <el-upload action="" :http-request="uploadImage" :show-file-list="false">
          <el-button size="small" type="default">{{settings.hasCertFile ? '更换文件' : '上传文件'}}</el-button>
        </el-upload>
      </el-descriptions-item>
    </el-descriptions>
    <el-descriptions
      title="小程序备案"
      :column="3"
      :size="size"
      border
      v-if="settings.appid"
    >
      <template #extra>
        <div v-if="icpEntranceInfo && icpEntranceInfo.status === 2">备案平台审核中</div>
        <div v-else-if="icpEntranceInfo && icpEntranceInfo.status === 4">备案管局审核中</div>
        <div v-else-if="icpEntranceInfo && icpEntranceInfo.status === 6">已完成备案</div>
        <el-button @click="addVerifyTask()" v-else-if="!settings.icpFiling || settings.icpFiling.verifyResult === 2">发起人脸核验</el-button>
        <div v-else-if="settings.icpFiling.verifyResult === 0 || settings.icpFiling.verifyResult === 1">
          <div>人脸核验中</div>
          <el-button @click="addVerifyTask()">重新发起人脸核验</el-button>
        </div>
        <div v-else-if="settings.icpFiling.verifyResult === 3 && (icpEntranceInfo.status !== 2 || icpEntranceInfo.status !== 4 || icpEntranceInfo.status !== 6)">
          <el-button @click="submitIcpFiling()">提交备案</el-button>
          <el-button @click="addVerifyTask()">重新发起人脸核验</el-button>
        </div>
      </template>
      <el-descriptions-item v-if="settings.icpFiling">
        <template #label>
          <div>备案主体信息(<a href="https://developers.weixin.qq.com/miniprogram/product/record/record_material.html" target="_blank">备案图片说明</a>)</div>
        </template>
        <el-button @click="showInputIcpSubject()" v-if="!settings.icpFiling || !settings.icpFiling.icpSubject">填写信息</el-button>
        <el-button @click="showInputIcpSubject(settings.icpFiling.icpSubject)" v-else>修改信息</el-button>
      </el-descriptions-item>
      <el-descriptions-item v-if="settings.icpFiling">
        <template #label>
          <div>微信小程序信息</div>
        </template>
        <el-button @click="showInputIcpApplets()" v-if="!settings.icpFiling || !settings.icpFiling.icpApplets">填写信息</el-button>
        <el-button @click="showInputIcpApplets(settings.icpFiling.icpApplets)" v-else>修改信息</el-button>
      </el-descriptions-item>
      <el-descriptions-item v-if="settings.icpFiling">
        <template #label>
          <div>其他备案媒体材料</div>
        </template>
        <el-button @click="showInputIcpMaterials()" v-if="!settings.icpFiling || !settings.icpFiling.icpMaterials">填写信息</el-button>
        <el-button @click="showInputIcpMaterials(settings.icpFiling.icpMaterials)" v-else>修改信息</el-button>
      </el-descriptions-item>
    </el-descriptions>
    <el-descriptions
      title="备案驳回提示"
      :column="1"
      :size="size"
      border
      v-if="icpEntranceInfo && (icpEntranceInfo.status === 3 || icpEntranceInfo.status === 5)"
    >
      <el-descriptions-item>驳回方：{{icpEntranceInfo.status === 3 ? '平台' : '管局'}}</el-descriptions-item>
      <el-descriptions-item v-for="(audit, index) in icpEntranceInfo.audit_data" :key="index">
        {{index + 1}}、{{audit.key_name}}，{{audit.error}}，{{audit.suggest}}
      </el-descriptions-item>
    </el-descriptions>
    <el-descriptions
      title="备案失败提示"
      :column="1"
      :size="size"
      border
      v-if="hints"
    >
      <el-descriptions-item v-for="item in hints" :key="item.err_field">
        {{item.err_field}}:{{item.errmsg}}
      </el-descriptions-item>
    </el-descriptions>
    <el-descriptions
      title="订阅消息模板"
      :column="2"
      :size="size"
      border
      v-if="settings.appid"
    >
      <el-descriptions-item>
        <template #label>
          <div>签到</div>
        </template>
        <div v-if="settings.signInTemplate">
          模板ID：{{settings.signInTemplate.templateId}}
          <div>-------------------------</div>
          <div v-for="item in settings.signInTemplate.miniappMsgTemplateItemList" :key="item.id">{{item.name}}：{{item.key}}</div>
        </div>
        <el-button @click="showThirdpartyMiniappMsgTemplateDialog(0)">设置</el-button>
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          <div>拼团成功通知</div>
        </template>
        <div v-if="settings.groupBuySuccessTemplate">
          模板ID：{{settings.groupBuySuccessTemplate.templateId}}
          <div>-------------------------</div>
          <div v-for="item in settings.groupBuySuccessTemplate.miniappMsgTemplateItemList" :key="item.id">{{item.name}}：{{item.key}}</div>
        </div>
        <el-button @click="showThirdpartyMiniappMsgTemplateDialog(1)">设置</el-button>
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          <div>拼团失败通知</div>
        </template>
        <div v-if="settings.groupBuyFailTemplate">
          模板ID：{{settings.groupBuyFailTemplate.templateId}}
          <div>-------------------------</div>
          <div v-for="item in settings.groupBuyFailTemplate.miniappMsgTemplateItemList" :key="item.id">{{item.name}}：{{item.key}}</div>
        </div>
        <el-button @click="showThirdpartyMiniappMsgTemplateDialog(2)">设置</el-button>
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          <div>订单完成通知</div>
        </template>
        <div v-if="settings.enrollSuccessTemplate">
          模板ID：{{settings.enrollSuccessTemplate.templateId}}
          <div>-------------------------</div>
          <div v-for="item in settings.enrollSuccessTemplate.miniappMsgTemplateItemList" :key="item.id">{{item.name}}：{{item.key}}</div>
        </div>
        <el-button @click="showThirdpartyMiniappMsgTemplateDialog(3)">设置</el-button>
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          <div>订单取消通知</div>
        </template>
        <div v-if="settings.enrollFailTemplate">
          模板ID：{{settings.enrollFailTemplate.templateId}}
          <div>-------------------------</div>
          <div v-for="item in settings.enrollFailTemplate.miniappMsgTemplateItemList" :key="item.id">{{item.name}}：{{item.key}}</div>
        </div>
        <el-button @click="showThirdpartyMiniappMsgTemplateDialog(4)">设置</el-button>
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          <div>奖励领取通知</div>
        </template>
        <div v-if="settings.acceptPrizeTemplate">
          模板ID：{{settings.acceptPrizeTemplate.templateId}}
          <div>-------------------------</div>
          <div v-for="item in settings.acceptPrizeTemplate.miniappMsgTemplateItemList" :key="item.id">{{item.name}}：{{item.key}}</div>
        </div>
        <el-button @click="showThirdpartyMiniappMsgTemplateDialog(5)">设置</el-button>
      </el-descriptions-item>
    </el-descriptions>
    <el-descriptions
      title="小程序样式"
      :column="1"
      :size="size"
      border
      v-if="settings.appid"
    >
      <template #extra>
        <el-button @click="showStyleDialog()">修改</el-button>
      </template>
      <el-descriptions-item>
        <template #label>
          <div>样式</div>
        </template>
        <div>{{settings.style}}</div>
      </el-descriptions-item>
    </el-descriptions>
    <el-descriptions
      title="小程序版本"
      :column="1"
      :size="size"
      border
      v-if="settings.appid"
    >
      <el-descriptions-item>
        <template #label>
          <div>当前版本</div>
        </template>
        <div v-if="settings.publishedThirdpartyTemplate">
          <div>版本：{{settings.publishedThirdpartyTemplate.version}}</div>
          <div>说明：{{settings.publishedThirdpartyTemplate.desc}}</div>
        </div>
        <div v-else>未发布</div>
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          <div>体验版本</div>
        </template>
        <div v-if="settings.experienceThirdpartyTemplate">
          <div>版本：{{settings.experienceThirdpartyTemplate.version}}</div>
          <div>说明：{{settings.experienceThirdpartyTemplate.desc}}</div>
          <div>
            <el-button size="small" @click="showQrcode()">查看二维码</el-button>
            <el-button size="small" @click="submitAudit()" v-if="settings.experienceThirdpartyTemplate.auditid === '0' || settings.experienceThirdpartyTemplate.auditid === ''">提交审核</el-button>
          </div>
          <div v-if="settings.latestAuditid !== '0' && settings.latestAuditid !== '' && settings.latestStatus === 2"><el-button size="small" @click="recallAudit()">撤回审核</el-button></div>
          <div v-if="settings.experienceThirdpartyTemplate.auditid !== '0' && settings.experienceThirdpartyTemplate.auditid !== ''">审核状态：{{settings.experienceThirdpartyTemplate.statusMsg || '查询中'}}</div>
          <div v-if="settings.experienceThirdpartyTemplate.status === 0"><el-button size="small" @click="publish()">全网发布</el-button></div>
          <div v-else-if="settings.experienceThirdpartyTemplate.status === 1 || settings.experienceThirdpartyTemplate.status === 3"><el-button size="small" @click="submitAudit()">重新提交</el-button></div>
          <div v-else-if="settings.experienceThirdpartyTemplate.status === 2">
            <el-button size="small" @click="recallAudit()">撤回审核</el-button>
            <el-button size="small" @click="speedupCodeAudit()">加急审核</el-button>
          </div>
          <div v-if="settings.experienceThirdpartyTemplate.reason">原因：{{settings.experienceThirdpartyTemplate.reason}}</div>
          <div v-if="settings.experienceThirdpartyTemplate.screenshot">截图：<el-image style="width: 200px; height: 200px;" :src="settings.experienceThirdpartyTemplate.screenshot" fit="contain"></el-image></div>
        </div>
        <div v-else>未发布</div>
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          <div>最新版本</div>
        </template>
        <div v-if="settings.latestThirdpartyTemplate">
          <div>版本：{{settings.latestThirdpartyTemplate.version}}</div>
          <div>说明：{{settings.latestThirdpartyTemplate.desc}}</div>
          <div><el-button size="small" @click="commitThirdpartyTemplate(settings.latestThirdpartyTemplate.id)">设为体验版</el-button></div>
        </div>
        <div v-else>未发布</div>
      </el-descriptions-item>
    </el-descriptions>
    <el-result icon="info" title="未授权小程序" sub-title="请使用小程序所有者的微信进行授权，如未有小程序请先申请" v-else>
      <template #extra>
        <el-button type="primary" plain @click="applyMiniProgramDialog.show = true">申请小程序</el-button>
        <el-button type="primary" @click="toAuth">授权小程序</el-button>
      </template>
    </el-result>
    <el-dialog
      v-model="showAuth"
      title="微信扫码授权"
      width="500px"
    >
      <div class="canvas"><canvas id="canvas"></canvas></div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="showAuth = false">取消</el-button>
          <el-button type="primary" @click="getSettings()"
            >已扫码授权</el-button
          >
        </span>
      </template>
    </el-dialog>
    <el-dialog
      v-model="qrcodeDialog.show"
      title="小程序体验版"
      width="500px"
    >
      <div class="canvas">
        <el-image style="width: 200px; height: 200px;" :src="qrcodeDialog.data.qrcode" fit="contain"></el-image>
      </div>
    </el-dialog>
    <el-dialog
      v-model="nicknameDialog.show"
      title="修改小程序名称"
      width="500px"
    >
      <el-form ref="ruleForm" :model="nicknameDialog.data" label-width="auto">
        <el-form-item label="名称" prop="nickname" :rules="[
          {required:false, message: '',trigger: 'blur'}
        ]">
          <el-input v-model="nicknameDialog.data.nickname" size="small" maxlength="100" clearable></el-input><el-button @click="checkNickname()" size="small">检测名称</el-button>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="nicknameDialog.show = false">取消</el-button>
          <el-button type="primary" @click="saveNickname()"
            >确定</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      v-model="checkNicknameDialog.show"
      title="小程序名称检测提示"
      width="500px"
    >
      <div v-if="checkNicknameDialog.data.hitCondition">{{checkNicknameDialog.data.wording}}</div>
      <div v-else>名称可用</div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="checkNicknameDialog.show = false"
            >知道了</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      v-model="signatureDialog.show"
      title="修改小程序介绍"
      width="500px"
    >
      <el-form ref="ruleForm" :model="signatureDialog.data" label-width="auto">
        <el-form-item label="介绍" prop="signature" :rules="[
          {required:false, message: '',trigger: 'blur'}
        ]">
          <el-input v-model="signatureDialog.data.signature" size="small" maxlength="100" clearable></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="signatureDialog.show = false">取消</el-button>
          <el-button type="primary" @click="saveSignature()"
            >确定</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      v-model="miniSettingDialog.show"
      title="修改小程序基础配置"
      width="500px"
    >
      <el-form ref="ruleForm" :model="miniSettingDialog.data" label-width="auto">
        <el-form-item label="商户号" prop="mchId" :rules="[
          {required:true, message: '商户号不能为空',trigger: 'blur'}
        ]">
          <el-input v-model="miniSettingDialog.data.mchId" size="small" maxlength="32" clearable></el-input>
        </el-form-item>
        <el-form-item label="支付通道" prop="isPlatformPayment" :rules="[
          {required:true, message: '支付通道不能为空', trigger: 'blur'}
        ]">
          <el-select v-model="miniSettingDialog.data.isPlatformPayment" placeholder="请选择通道" size="small">
            <el-option key="1" label="服务商通道" value="1"></el-option>
            <el-option key="0" label="商户通道" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="密钥" prop="partnerKey" :rules="[
          {required:false, message: '', trigger: 'blur'}
        ]" v-if="miniSettingDialog.data.isPlatformPayment === '0'">
          <el-input v-model="miniSettingDialog.data.partnerKey" size="small" maxlength="32" clearable></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="miniSettingDialog.show = false">取消</el-button>
          <el-button type="primary" @click="saveMiniSetting()"
            >确定</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      v-model="thirdpartyMiniappMsgTemplateDialog.show"
      title="设置订阅消息模板"
      width="500px"
    >
      <el-form ref="ruleForm" :model="thirdpartyMiniappMsgTemplateDialog.data" label-width="auto">
        <el-form-item label="模板ID" prop="templateId">
          <el-input v-model="thirdpartyMiniappMsgTemplateDialog.data.templateId" size="small" maxlength="64" clearable></el-input>
        </el-form-item>
        <el-form-item v-for="item in thirdpartyMiniappMsgTemplateDialog.data.miniappMsgTemplateItemList" :key="item.id" :label="item.name" prop="key">
          <el-input v-model="item.key" size="small" maxlength="64" clearable></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="thirdpartyMiniappMsgTemplateDialog.show = false">取消</el-button>
          <el-button type="primary" @click="saveThirdpartyMiniappMsgTemplate()"
            >确定</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      v-model="styleDialog.show"
      title="修改小程序样式"
      width="500px"
    >
      <el-form ref="ruleForm" :model="styleDialog.data" label-width="auto">
        <el-form-item label="样式" prop="style" :rules="[
          {required:true, message: 'appid不能为空',trigger: 'blur'}
        ]">
          <el-input v-model="styleDialog.data.style" size="small" type="textarea" :rows="10" clearable></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="styleDialog.show = false">取消</el-button>
          <el-button type="primary" @click="saveStyle()"
            >确定</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      v-model="applyMiniProgramDialog.show"
      title="申请小程序"
      width="500px"
    >
      <el-form ref="ruleForm" :model="applyMiniProgramDialog.data" label-width="auto">
        <el-form-item label="企业名" prop="name" :rules="[
          {required:true, message: '企业名不能为空',trigger: 'blur'}
        ]">
          <el-input v-model="applyMiniProgramDialog.data.name" size="small" maxlength="255" clearable></el-input>
        </el-form-item>
        <el-form-item label="企业代码" prop="code" :rules="[
          {required:true, message: '企业代码不能为空',trigger: 'blur'}
        ]">
          <el-input v-model="applyMiniProgramDialog.data.code" size="small" maxlength="32" clearable></el-input>
        </el-form-item>
        <el-form-item label="企业代码类型" prop="code_type" :rules="[
          {required:true, message: '企业代码类型不能为空', trigger: 'blur'}
        ]">
          <el-select v-model="applyMiniProgramDialog.code_type" placeholder="请选择类型" size="small">
            <el-option key="1" label="统一社会信用代码" value="1"></el-option>
            <el-option key="2" label="组织机构代码" value="2"></el-option>
            <el-option key="3" label="营业执照注册号" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="法人微信号" prop="legal_persona_wechat" :rules="[
          {required:true, message: '法人微信号不能为空',trigger: 'blur'}
        ]">
          <el-input v-model="applyMiniProgramDialog.data.legal_persona_wechat" size="small" maxlength="255" clearable></el-input>
        </el-form-item>
        <el-form-item label="法人姓名（绑定银行卡）" prop="legal_persona_name" :rules="[
          {required:true, message: '法人姓名不能为空',trigger: 'blur'}
        ]">
          <el-input v-model="applyMiniProgramDialog.data.legal_persona_name" size="small" maxlength="255" clearable></el-input>
        </el-form-item>
        <el-form-item label="第三方联系电话" prop="component_phone" :rules="[
          {required:false, message: '',trigger: 'blur'}
        ]">
          <el-input v-model="applyMiniProgramDialog.data.component_phone" size="small" maxlength="32" clearable></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="applyMiniProgramDialog.show = false">取消</el-button>
          <el-button type="primary" @click="saveApplyMiniProgram()"
            >确定</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      v-model="categoryDialog.show"
      title="添加类目"
      width="500px"
    >
      <el-form ref="ruleForm" label-width="auto">
        <el-form-item label="一级类目">
          <el-select @change="selectFirstCategory()" v-model="categoryDialog.selectedFirstCategoryId" placeholder="请选择类目" size="small">
            <el-option v-for="firstCategory in categoryDialog.firstCategories" :key="firstCategory.id" :label="firstCategory.name" :value="firstCategory.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="二级类目" v-if="categoryDialog.secondCategories">
          <el-select @change="selectSecondCategory()" v-model="categoryDialog.selectedSecondCategoryId" placeholder="请选择类目" size="small">
            <el-option v-for="secondCategory in categoryDialog.secondCategories" :key="secondCategory.id" :label="secondCategory.name" :value="secondCategory.id"></el-option>
          </el-select>
        </el-form-item>
        <el-descriptions
          title="类目详情"
          :column="2"
          border
          v-if="categoryDialog.selectedCategory"
        >
          <el-descriptions-item>
            <template #label>
              <div>名称</div>
            </template>
            {{categoryDialog.selectedCategory.name}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template #label>
              <div>类型</div>
            </template>
            {{categoryDialog.selectedCategory.sensitive_type === 1 ? '敏感类目' : '非敏感类目'}}
          </el-descriptions-item>
          <el-descriptions-item v-if="categoryDialog.selectedCategory.sensitive_type === 1">
            <template #label>
              <div>所需资质{{categoryDialog.selectedCategory.qualify.remark ? '(' + categoryDialog.selectedCategory.qualify.remark + ')' : ''}}</div>
            </template>
            <div v-for="(exter, index) in categoryDialog.selectedCategory.qualify.exter_list" :key="index">
              <li v-for="(inner, index) in exter.inner_list" :key="index">
                {{inner.name}}<a style="padding-left: 8px;" :href="inner.url" target="_blank" v-if="inner.url">查看示例</a>
                <el-upload style="padding-left: 8px;" action="" :before-upload="startUpload(inner.name)" :http-request="uploadCertificate" :show-file-list="false">
                  <el-button size="small" type="default">上传资质证明</el-button>
                </el-upload>
              </li>
            </div>
          </el-descriptions-item>
        </el-descriptions>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="categoryDialog.show = false">取消</el-button>
          <el-button type="primary" @click="saveCategory()"
            >确定</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      v-model="icpSubjectDialog.show"
      title="备案主体信息"
      width="500px"
    >
      <el-scrollbar height="400px">
        <el-form ref="ruleForm" :model="icpSubjectDialog.data" label-width="auto">
          <el-form-item label="主体性质" prop="baseInfoType" :rules="[
            {required:true, message: '主体性质不能为空', trigger: 'blur'}
          ]">
            <el-select v-model="icpSubjectDialog.data.baseInfoType" placeholder="请选择主体性质" size="small">
              <el-option :key="index" :label="icpSubjectType.name" :value="icpSubjectType.type" v-for="(icpSubjectType, index) in icpSubjectTypes"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="主办单位名称" prop="baseInfoName" :rules="[
            {required:true, message: '主办单位名称不能为空',trigger: 'blur'}
          ]">
            <el-input v-model="icpSubjectDialog.data.baseInfoName" size="small" maxlength="255" clearable></el-input>
          </el-form-item>
          <el-form-item label="备案省份" prop="baseInfoProvince" :rules="[
            {required:true, message: '备案省份不能为空', trigger: 'blur'}
          ]">
            <el-select @change="selectProvince()" v-model="icpSubjectDialog.data.baseInfoProvince" placeholder="请选择备案省份" size="small">
              <el-option :key="index" :label="icpDistrictCode.name" :value="icpDistrictCode.code" v-for="(icpDistrictCode, index) in icpDistrictCodeList"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备案城市" prop="baseInfoCity" :rules="[
            {required:true, message: '备案城市不能为空', trigger: 'blur'}
          ]" v-if="icpSubjectDialog.data.baseInfoProvince">
            <el-select @change="selectCity()" v-model="icpSubjectDialog.data.baseInfoCity" placeholder="请选择备案城市" size="small">
              <el-option :key="index" :label="icpDistrictCode.name" :value="icpDistrictCode.code" v-for="(icpDistrictCode, index) in cityList"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备案县区" prop="baseInfoDistrict" :rules="[
            {required:true, message: '备案县区不能为空', trigger: 'blur'}
          ]" v-if="icpSubjectDialog.data.baseInfoCity">
            <el-select v-model="icpSubjectDialog.data.baseInfoDistrict" placeholder="请选择备案县区" size="small">
              <el-option :key="index" :label="icpDistrictCode.name" :value="icpDistrictCode.code" v-for="(icpDistrictCode, index) in districtList"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="通讯地址" prop="baseInfoAddress" :rules="[
            {required:true, message: '通讯地址不能为空',trigger: 'blur'}
          ]">
            <el-input v-model="icpSubjectDialog.data.baseInfoAddress" size="small" maxlength="255" clearable></el-input>
          </el-form-item>
          <el-form-item label="临时居住证明照片" prop="baseInfoResidencePermit">
            <el-image style="width: 100px; height: 100px" :src="icpSubjectDialog.data.personalInfoResidencePermitImage" :fit="contain" v-if="icpSubjectDialog.data.personalInfoResidencePermitImage" />
            <el-upload action="" :http-request="uploadIcpMedia" :data="{ certificateType: '5', icpOrderField: 'icp_subject.personal_info.residence_permit'}" :show-file-list="false">
              <el-button size="small" type="default">上传照片</el-button>（个人备案且非本省人员，需要提供居住证、暂住证、社保证明、房产证等临时居住证明）
            </el-upload>
          </el-form-item>
          <el-form-item label="主体证件类型" prop="organizeInfoCertificateType" :rules="[
            {required:true, message: '主体证件类型不能为空', trigger: 'blur'}
          ]">
            <el-select v-model="icpSubjectDialog.data.organizeInfoCertificateType" placeholder="请选择主体证件类型" size="small">
              <el-option :key="icpCertificateType.type" :label="icpCertificateType.name" :value="icpCertificateType.type" v-for="icpCertificateType in icpCertificateTypes"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="主体证件号码" prop="organizeInfoCertificateNumber" :rules="[
            {required:true, message: '主体证件号码不能为空',trigger: 'blur'}
          ]">
            <el-input v-model="icpSubjectDialog.data.organizeInfoCertificateNumber" size="small" maxlength="32" clearable></el-input>
          </el-form-item>
          <el-form-item label="主体证件住所" prop="organizeInfoCertificateAddress" :rules="[
            {required:true, message: '主体证件住所不能为空',trigger: 'blur'}
          ]">
            <el-input v-model="icpSubjectDialog.data.organizeInfoCertificateAddress" size="small" maxlength="255" clearable></el-input>
          </el-form-item>
          <el-form-item label="主体证件照片" prop="organizeInfoCertificatePhoto">
            <el-image style="width: 100px; height: 100px" :src="icpSubjectDialog.data.organizeInfoCertificatePhotoImage" :fit="contain" v-if="icpSubjectDialog.data.organizeInfoCertificatePhotoImage" />
            <el-upload action="" :http-request="uploadIcpMedia" :data="{ certificateType: '1', icpOrderField: 'icp_subject.organize_info.certificate_photo'}" :show-file-list="false">
              <el-button size="small" type="default">上传照片</el-button>
            </el-upload>
          </el-form-item>
          <el-form-item label="负责人姓名" prop="principalInfoName" :rules="[
            {required:true, message: '负责人姓名不能为空',trigger: 'blur'}
          ]">
            <el-input v-model="icpSubjectDialog.data.principalInfoName" size="small" maxlength="32" clearable></el-input>
          </el-form-item>
          <el-form-item label="负责人联系方式" prop="principalInfoMobile" :rules="[
            {required:true, message: '负责人联系方式不能为空',trigger: 'blur'}
          ]">
            <el-input v-model="icpSubjectDialog.data.principalInfoMobile" size="small" maxlength="32" clearable></el-input>
          </el-form-item>
          <el-form-item label="负责人电子邮件" prop="principalInfoEmail" :rules="[
            {required:true, message: '负责人电子邮件不能为空',trigger: 'blur'}
          ]">
            <el-input v-model="icpSubjectDialog.data.principalInfoEmail" size="small" maxlength="255" clearable></el-input>
          </el-form-item>
          <el-form-item label="负责人应急联系方式" prop="principalInfoEmergencyContact" :rules="[
            {required:true, message: '负责人应急联系方式不能为空',trigger: 'blur'}
          ]">
            <el-input v-model="icpSubjectDialog.data.principalInfoEmergencyContact" size="small" maxlength="32" clearable></el-input>
          </el-form-item>
          <el-form-item label="负责人证件类型" prop="principalInfoCertificateType" :rules="[
            {required:true, message: '负责人证件类型不能为空', trigger: 'blur'}
          ]">
            <el-select v-model="icpSubjectDialog.data.principalInfoCertificateType" placeholder="请选择负责人证件类型" size="small">
              <el-option :key="icpCertificateType.type" :label="icpCertificateType.name" :value="icpCertificateType.type" v-for="icpCertificateType in icpCertificateTypes"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="负责人证件号码" prop="principalInfoCertificateNumber" :rules="[
            {required:true, message: '负责人证件号码不能为空',trigger: 'blur'}
          ]">
            <el-input v-model="icpSubjectDialog.data.principalInfoCertificateNumber" size="small" maxlength="32" clearable></el-input>
          </el-form-item>
          <el-form-item label="负责人证件有效期起始日期" prop="principalInfoCertificateValidityDateStart" :rules="[
            {required:true, message: '负责人证件有效期起始日期不能为空',trigger: 'blur'}
          ]">
            <el-input v-model="icpSubjectDialog.data.principalInfoCertificateValidityDateStart" placeholder="示例：20230815" size="small" maxlength="32" clearable></el-input>
          </el-form-item>
          <el-form-item label="负责人证件有效期终止日期" prop="principalInfoCertificateValidityDateEnd" :rules="[
            {required:true, message: '负责人证件有效期终止日期不能为空',trigger: 'blur'}
          ]">
            <el-input v-model="icpSubjectDialog.data.principalInfoCertificateValidityDateEnd" placeholder="示例：20230815，如长期有效则填写“长期”" size="small" maxlength="32" clearable></el-input>
          </el-form-item>
          <el-form-item label="负责人证件正面照片" prop="principalInfoCertificatePhotoFront">
            <el-image style="width: 100px; height: 100px" :src="icpSubjectDialog.data.principalInfoCertificatePhotoFrontImage" :fit="contain" v-if="icpSubjectDialog.data.principalInfoCertificatePhotoFrontImage" />
            <el-upload action="" :http-request="uploadIcpMedia" :data="{ certificateType: '2', icpOrderField: 'icp_subject.principal_info.certificate_photo_front'}" :show-file-list="false">
              <el-button size="small" type="default">上传照片</el-button>
            </el-upload>
          </el-form-item>
          <el-form-item label="负责人证件背面照片" prop="principalInfoCertificatePhotoBack">
            <el-image style="width: 100px; height: 100px" :src="icpSubjectDialog.data.principalInfoCertificatePhotoBackImage" :fit="contain" v-if="icpSubjectDialog.data.principalInfoCertificatePhotoBackImage" />
            <el-upload action="" :http-request="uploadIcpMedia" :data="{ certificateType: '2', icpOrderField: 'icp_subject.principal_info.certificate_photo_back'}" :show-file-list="false">
              <el-button size="small" type="default">上传照片</el-button>
            </el-upload>
          </el-form-item>
          <el-form-item label="授权书" prop="principalInfoAuthorizationLetter">
            <el-image style="width: 100px; height: 100px" :src="icpSubjectDialog.data.principalInfoAuthorizationLetterImage" :fit="contain" v-if="icpSubjectDialog.data.principalInfoAuthorizationLetterImage" />
            <el-upload action="" :http-request="uploadIcpMedia" :data="{ certificateType: '0', icpOrderField: 'icp_subject.principal_info.authorization_letter'}" :show-file-list="false">
              <el-button size="small" type="default">上传照片</el-button>（当主体负责人不是法人时需要主体负责人授权书，当小程序负责人不是法人时需要小程序负责人授权书）
            </el-upload>
          </el-form-item>
          <el-form-item label="法人代表姓名" prop="legalPersonInfoName">
            <el-input v-model="icpSubjectDialog.data.legalPersonInfoName" size="small" maxlength="32" clearable></el-input>
          </el-form-item>
          <el-form-item label="法人证件号码" prop="legalPersonInfoCertificateNumber">
            <el-input v-model="icpSubjectDialog.data.legalPersonInfoCertificateNumber" size="small" maxlength="32" clearable></el-input>
          </el-form-item>
        </el-form>
      </el-scrollbar>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="icpSubjectDialog.show = false">取消</el-button>
          <el-button type="primary" @click="saveIcpSubject()"
            >确定</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      v-model="icpAppletsDialog.show"
      title="微信小程序信息"
      width="500px"
    >
      <el-scrollbar height="400px">
        <el-form ref="ruleForm" :model="icpAppletsDialog.data" label-width="auto">
          <el-form-item label="服务内容类型" prop="baseInfoServiceContentTypes" :rules="[
            {required:true, message: '服务内容类型不能为空', trigger: 'blur'}
          ]">
            <el-button :key="index" :icon="Delete" @click="removeBaseInfoServiceContentType(baseInfoServiceContentType.type)" v-for="(baseInfoServiceContentType, index) in icpAppletsDialog.data.baseInfoServiceContentTypes">{{baseInfoServiceContentType.name}}</el-button>
            <el-button @click="showServiceContentTypeDialog">添加</el-button>
          </el-form-item>
          <el-form-item label="前置审批项" prop="baseInfoNrlxDetails" :rules="[
            {required:true, message: '前置审批项不能为空', trigger: 'blur'}
          ]">
            <el-button :key="index" :icon="Delete" @click="removeBaseInfoNrlxDetail(baseInfoNrlxDetail.type)" v-for="(baseInfoNrlxDetail, index) in icpAppletsDialog.data.baseInfoNrlxDetails">{{baseInfoNrlxDetail.name}}，审批号：{{baseInfoNrlxDetail.code}}
              <el-image style="width: 20px; height: 20px" :src="baseInfoNrlxDetail.image" :fit="contain" />
            </el-button>
            <el-button @click="showNrlxTypeDialog">添加</el-button>
          </el-form-item>
          <el-form-item label="实际经营内容" prop="baseInfoComment" :rules="[
            {required:true, message: '实际经营内容不能为空',trigger: 'blur'}
          ]">
            <el-input v-model="icpAppletsDialog.data.baseInfoComment" placeholder="限20-200字" size="small" maxlength="200" clearable></el-input>
          </el-form-item>
          <el-form-item label="负责人姓名" prop="principalInfoName" :rules="[
            {required:true, message: '负责人姓名不能为空',trigger: 'blur'}
          ]">
            <el-input v-model="icpAppletsDialog.data.principalInfoName" size="small" maxlength="32" clearable></el-input>
          </el-form-item>
          <el-form-item label="负责人联系方式" prop="principalInfoMobile" :rules="[
            {required:true, message: '负责人联系方式不能为空',trigger: 'blur'}
          ]">
            <el-input v-model="icpAppletsDialog.data.principalInfoMobile" size="small" maxlength="32" clearable></el-input>
          </el-form-item>
          <el-form-item label="负责人电子邮件" prop="principalInfoEmail" :rules="[
            {required:true, message: '负责人电子邮件不能为空',trigger: 'blur'}
          ]">
            <el-input v-model="icpAppletsDialog.data.principalInfoEmail" size="small" maxlength="255" clearable></el-input>
          </el-form-item>
          <el-form-item label="负责人应急联系方式" prop="principalInfoEmergencyContact" :rules="[
            {required:true, message: '负责人应急联系方式不能为空',trigger: 'blur'}
          ]">
            <el-input v-model="icpAppletsDialog.data.principalInfoEmergencyContact" size="small" maxlength="32" clearable></el-input>
          </el-form-item>
          <el-form-item label="负责人证件类型" prop="principalInfoCertificateType" :rules="[
            {required:true, message: '负责人证件类型不能为空', trigger: 'blur'}
          ]">
            <el-select v-model="icpAppletsDialog.data.principalInfoCertificateType" placeholder="请选择负责人证件类型" size="small">
              <el-option :key="icpCertificateType.type" :label="icpCertificateType.name" :value="icpCertificateType.type" v-for="icpCertificateType in icpCertificateTypes"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="负责人证件号码" prop="principalInfoCertificateNumber" :rules="[
            {required:true, message: '负责人证件号码不能为空',trigger: 'blur'}
          ]">
            <el-input v-model="icpAppletsDialog.data.principalInfoCertificateNumber" size="small" maxlength="32" clearable></el-input>
          </el-form-item>
          <el-form-item label="负责人证件有效期起始日期" prop="principalInfoCertificateValidityDateStart" :rules="[
            {required:true, message: '负责人证件有效期起始日期不能为空',trigger: 'blur'}
          ]">
            <el-input v-model="icpAppletsDialog.data.principalInfoCertificateValidityDateStart" placeholder="示例：20230815" size="small" maxlength="32" clearable></el-input>
          </el-form-item>
          <el-form-item label="负责人证件有效期终止日期" prop="principalInfoCertificateValidityDateEnd" :rules="[
            {required:true, message: '负责人证件有效期终止日期不能为空',trigger: 'blur'}
          ]">
            <el-input v-model="icpAppletsDialog.data.principalInfoCertificateValidityDateEnd" placeholder="示例：20230815，如长期有效则填写“长期”" size="small" maxlength="32" clearable></el-input>
          </el-form-item>
          <el-form-item label="负责人证件正面照片" prop="principalInfoCertificatePhotoFront">
            <el-image style="width: 100px; height: 100px" :src="icpAppletsDialog.data.principalInfoCertificatePhotoFrontImage" :fit="contain" v-if="icpAppletsDialog.data.principalInfoCertificatePhotoFrontImage" />
            <el-upload action="" :http-request="uploadIcpMedia" :data="{ certificateType: '2', icpOrderField: 'icp_applets.principal_info.certificate_photo_front'}" :show-file-list="false">
              <el-button size="small" type="default">上传照片</el-button>
            </el-upload>
          </el-form-item>
          <el-form-item label="负责人证件背面照片" prop="principalInfoCertificatePhotoBack">
            <el-image style="width: 100px; height: 100px" :src="icpAppletsDialog.data.principalInfoCertificatePhotoBackImage" :fit="contain" v-if="icpAppletsDialog.data.principalInfoCertificatePhotoBackImage" />
            <el-upload action="" :http-request="uploadIcpMedia" :data="{ certificateType: '2', icpOrderField: 'icp_applets.principal_info.certificate_photo_back'}" :show-file-list="false">
              <el-button size="small" type="default">上传照片</el-button>
            </el-upload>
          </el-form-item>
          <el-form-item label="授权书" prop="principalInfoAuthorizationLetter">
            <el-image style="width: 100px; height: 100px" :src="icpAppletsDialog.data.principalInfoAuthorizationLetterImage" :fit="contain" v-if="icpAppletsDialog.data.principalInfoAuthorizationLetterImage" />
            <el-upload action="" :http-request="uploadIcpMedia" :data="{ certificateType: '0', icpOrderField: 'icp_applets.principal_info.authorization_letter'}" :show-file-list="false">
              <el-button size="small" type="default">上传照片</el-button>（当主体负责人不是法人时需要主体负责人授权书，当小程序负责人不是法人时需要小程序负责人授权书）
            </el-upload>
          </el-form-item>
        </el-form>
      </el-scrollbar>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="icpAppletsDialog.show = false">取消</el-button>
          <el-button type="primary" @click="saveIcpApplets()"
            >确定</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      v-model="icpMaterialsDialog.show"
      title="其他备案媒体材料"
      width="500px"
    >
      <el-scrollbar height="400px">
        <el-form ref="ruleForm" label-width="auto">
          <el-form-item label="互联网信息服务承诺书">
            <el-image style="width: 100px; height: 100px" :src="icpMaterialsDialog.data.commitmentLetterImage" :fit="contain" v-if="icpMaterialsDialog.data.commitmentLetterImage" />
            <el-upload action="" :http-request="uploadIcpMedia" :data="{ certificateType: '0', icpOrderField: 'icp_materials.commitment_letter'}" :show-file-list="false">
              <el-button size="small" type="default">上传照片</el-button>
            </el-upload>
          </el-form-item>
          <el-form-item label="主体更名函">
            <el-image style="width: 100px; height: 100px" :src="icpMaterialsDialog.data.businessNameChangeLetterImage" :fit="contain" v-if="icpMaterialsDialog.data.businessNameChangeLetterImage" />
            <el-upload action="" :http-request="uploadIcpMedia" :data="{ certificateType: '0', icpOrderField: 'icp_materials.business_name_change_letter'}" :show-file-list="false">
              <el-button size="small" type="default">上传照片</el-button>
            </el-upload>
          </el-form-item>
          <el-form-item label="党建确认函">
            <el-image style="width: 100px; height: 100px" :src="icpMaterialsDialog.data.partyBuildingConfirmationLetterImage" :fit="contain" v-if="icpMaterialsDialog.data.partyBuildingConfirmationLetterImage" />
            <el-upload action="" :http-request="uploadIcpMedia" :data="{ certificateType: '0', icpOrderField: 'icp_materials.party_building_confirmation_letter'}" :show-file-list="false">
              <el-button size="small" type="default">上传照片</el-button>
            </el-upload>
          </el-form-item>
          <el-form-item label="承诺视频">
            <video style="width: 100px; height: 100px" :src="icpMaterialsDialog.data.promiseVideoImage" :fit="contain" v-if="icpMaterialsDialog.data.promiseVideoImage" />
            <el-upload action="" :http-request="uploadIcpMedia" :data="{ certificateType: '0', icpOrderField: 'icp_materials.promise_video'}" :show-file-list="false">
              <el-button size="small" type="default">上传照片</el-button>
            </el-upload>
          </el-form-item>
          <el-form-item label="网站备案信息真实性责任告知书">
            <el-image style="width: 100px; height: 100px" :src="icpMaterialsDialog.data.authenticityResponsibilityLetterImage" :fit="contain" v-if="icpMaterialsDialog.data.authenticityResponsibilityLetterImage" />
            <el-upload action="" :http-request="uploadIcpMedia" :data="{ certificateType: '0', icpOrderField: 'icp_materials.authenticity_responsibility_letter'}" :show-file-list="false">
              <el-button size="small" type="default">上传照片</el-button>
            </el-upload>
          </el-form-item>
          <el-form-item label="小程序备案信息真实性承诺书">
            <el-image style="width: 100px; height: 100px" :src="icpMaterialsDialog.data.authenticityCommitmentLetterImage" :fit="contain" v-if="icpMaterialsDialog.data.authenticityCommitmentLetterImage" />
            <el-upload action="" :http-request="uploadIcpMedia" :data="{ certificateType: '0', icpOrderField: 'icp_materials.authenticity_commitment_letter'}" :show-file-list="false">
              <el-button size="small" type="default">上传照片</el-button>
            </el-upload>
          </el-form-item>
          <el-form-item label="小程序建设方案书">
            <el-image style="width: 100px; height: 100px" :src="icpMaterialsDialog.data.websiteConstructionProposalImage" :fit="contain" v-if="icpMaterialsDialog.data.websiteConstructionProposalImage" />
            <el-upload action="" :http-request="uploadIcpMedia" :data="{ certificateType: '0', icpOrderField: 'icp_materials.website_construction_proposal'}" :show-file-list="false">
              <el-button size="small" type="default">上传照片</el-button>
            </el-upload>
          </el-form-item>
          <el-form-item label="主体其它附件">
            <el-image style="width: 100px; height: 100px" :src="subjectOtherMaterial.url" :fit="contain" @click="removeSubjectOtherMaterial(subjectOtherMaterial.url)" :key="index" v-for="(subjectOtherMaterial, index) in icpMaterialsDialog.data.subjectOtherMaterials"/>
            <el-upload action="" :http-request="uploadIcpMedia" :data="{ certificateType: '0', icpOrderField: 'icp_materials.subject_other_materials'}" :show-file-list="true">
              <el-button size="small" type="default">上传照片</el-button>
            </el-upload>
          </el-form-item>
          <el-form-item label="小程序其它附件">
            <el-image style="width: 100px; height: 100px" :src="appletsOtherMaterial.url" :fit="contain" @click="removeAppletsOtherMaterial(appletsOtherMaterial.url)" :key="index" v-for="(appletsOtherMaterial, index) in icpMaterialsDialog.data.appletsOtherMaterials"/>
            <el-upload action="" :http-request="uploadIcpMedia" :data="{ certificateType: '0', icpOrderField: 'icp_materials.applets_other_materials'}" :show-file-list="true">
              <el-button size="small" type="default">上传照片</el-button>
            </el-upload>
          </el-form-item>
          <el-form-item label="手持证件照">
            <el-image style="width: 100px; height: 100px" :src="icpMaterialsDialog.data.holdingCertificatePhotoImage" :fit="contain" v-if="icpMaterialsDialog.data.holdingCertificatePhotoImage" />
            <el-upload action="" :http-request="uploadIcpMedia" :data="{ certificateType: '0', icpOrderField: 'icp_materials.holding_certificate_photo'}" :show-file-list="false">
              <el-button size="small" type="default">上传照片</el-button>
            </el-upload>
          </el-form-item>
        </el-form>
      </el-scrollbar>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="icpMaterialsDialog.show = false">取消</el-button>
          <el-button type="primary" @click="saveIcpMaterials()"
            >确定</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      v-model="serviceContentTypeDialog.show"
      title="服务内容类型"
      width="500px"
    >
      <el-scrollbar height="400px">
        <el-form label-width="auto">
          <el-form-item label="一级服务内容类型">
            <el-select v-model="serviceContentTypeDialog.data.parentServiceContentType" placeholder="请选择一级服务内容类型" size="small">
              <el-option :key="index" :label="parentServiceContentType.name" :value="parentServiceContentType.type" v-for="(parentServiceContentType, index) in icpServiceContentTypes.filter(icpServiceContentType => icpServiceContentType.parent_type === 0)"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="二级服务内容类型" v-if="serviceContentTypeDialog.data.parentServiceContentType">
            <el-select v-model="serviceContentTypeDialog.data.serviceContentType" placeholder="请选择二级服务内容类型" size="small">
              <el-option :key="index" :label="serviceContentType.name" :value="serviceContentType.type" v-for="(serviceContentType, index) in icpServiceContentTypes.filter(icpServiceContentType => icpServiceContentType.parent_type === serviceContentTypeDialog.data.parentServiceContentType)"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-scrollbar>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="hideServiceContentTypeDialog">取消</el-button>
          <el-button type="primary" @click="saveServiceContentType()"
            >确定</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      v-model="nrlxTypeDialog.show"
      title="前置审批项"
      width="500px"
    >
      <el-scrollbar height="400px">
        <el-form label-width="auto">
          <el-form-item label="前置审批类型">
            <el-select v-model="nrlxTypeDialog.data.type" placeholder="请选择前置审批类型" size="small">
              <el-option :key="index" :label="nrlxType.name" :value="nrlxType.type" v-for="(nrlxType, index) in icpNrlxTypes"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="前置审批号" :rules="[
            {required:true, message: '前置审批号不能为空',trigger: 'blur'}
          ]">
            <el-input v-model="nrlxTypeDialog.data.code" size="small" maxlength="255" clearable></el-input>
          </el-form-item>
          <el-form-item label="前置审批媒体材料">
            <el-image style="width: 100px; height: 100px" :src="nrlxTypeDialog.data.image" :fit="contain" v-if="nrlxTypeDialog.data.image" />
            <el-upload action="" :http-request="uploadIcpMedia" :data="{ certificateType: '0', icpOrderField: 'icp_applets.base_info.nrlx_details'}" :show-file-list="false">
              <el-button size="small" type="default">上传照片</el-button>
            </el-upload>
          </el-form-item>
        </el-form>
      </el-scrollbar>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="hideNrlxTypeDialog">取消</el-button>
          <el-button type="primary" @click="saveNrlxType()"
            >确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import axios from '@/common/utils/axios'
import upload from '@/common/utils/upload'
import { ElDescriptions, ElDescriptionsItem, ElImage, ElButton, ElResult, ElDialog, ElForm, ElFormItem, ElInput, ElSelect, ElOption, ElUpload, ElMessage, ElScrollbar } from 'element-plus'
import { reactive, ref } from 'vue'

export default {
  components: {
    ElDescriptions, ElDescriptionsItem, ElButton, ElResult, ElDialog, ElForm, ElFormItem, ElInput, ElSelect, ElOption, ElUpload, ElImage, ElScrollbar
  },
  setup () {
    const settings = reactive({
      appid: '',
      mchId: '',
      partnerKey: '',
      isPlatformPayment: '',
      hasCertFile: false,
      publishedThirdpartyTemplate: null,
      experienceThirdpartyTemplate: null,
      latestThirdpartyTemplate: null,
      signInTemplate: null,
      groupBuySuccessTemplate: null,
      groupBuyFailTemplate: null,
      enrollSuccessTemplate: null,
      enrollFailTemplate: null,
      acceptPrizeTemplate: null,
      accountBasicInfo: null,
      category: null,
      latestAuditid: '',
      latestStatus: '',
      icpFiling: null
    })
    const showAuth = ref(false)
    const qrcodeDialog = reactive({
      show: false,
      data: {
        qrcode: ''
      }
    })
    const miniSettingDialog = reactive({
      show: false,
      data: {
        mchId: '',
        partnerKey: '',
        isPlatformPayment: ''
      }
    })
    const nicknameDialog = reactive({
      show: false,
      data: {
        nickname: ''
      }
    })
    const signatureDialog = reactive({
      show: false,
      data: {
        signature: ''
      }
    })
    const checkNicknameDialog = reactive({
      show: false,
      data: {
        hitCondition: false,
        wording: ''
      }
    })
    const thirdpartyMiniappMsgTemplateDialog = reactive({
      show: false,
      data: {
        templateId: '',
        type: 0,
        miniappMsgTemplateItemList: null
      }
    })
    const applyMiniProgramDialog = reactive({
      show: false,
      data: {
        name: '',
        code: '',
        code_type: 1,
        legal_persona_name: '',
        legal_persona_wechat: '',
        component_phone: ''
      }
    })
    const categoryDialog = reactive({
      show: false,
      firstCategories: null,
      selectedFirstCategoryId: '',
      secondCategories: null,
      selectedSecondCategoryId: '',
      selectedCategory: null,
      allCategories: null,
      certificateFileList: [],
      innerName: ''
    })
    const styleDialog = reactive({
      show: false,
      data: {
        style: ''
      }
    })
    const mediaArray = reactive([])
    const icpSubjectDialog = reactive({
      show: false,
      data: {
        baseInfoType: '',
        baseInfoName: '',
        baseInfoProvince: '',
        baseInfoCity: '',
        baseInfoDistrict: '',
        baseInfoAddress: '',
        personalInfoResidencePermit: '',
        personalInfoResidencePermitImage: '',
        organizeInfoCertificateType: '',
        organizeInfoCertificateNumber: '',
        organizeInfoCertificateAddress: '',
        organizeInfoCertificatePhoto: '',
        organizeInfoCertificatePhotoImage: '',
        principalInfoName: '',
        principalInfoMobile: '',
        principalInfoEmail: '',
        principalInfoEmergencyContact: '',
        principalInfoCertificateType: '',
        principalInfoCertificateNumber: '',
        principalInfoCertificateValidityDateStart: '',
        principalInfoCertificateValidityDateEnd: '',
        principalInfoCertificatePhotoFront: '',
        principalInfoCertificatePhotoFrontImage: '',
        principalInfoCertificatePhotoBack: '',
        principalInfoCertificatePhotoBackImage: '',
        principalInfoAuthorizationLetter: '',
        principalInfoAuthorizationLetterImage: '',
        principalInfoVerifyTaskId: '',
        legalPersonInfoName: '',
        legalPersonInfoCertificateNumber: ''
      }
    })
    const icpAppletsDialog = reactive({
      show: false,
      data: {
        baseInfoAppid: '',
        baseInfoName: '',
        baseInfoServiceContentTypes: [],
        baseInfoNrlxDetails: [],
        baseInfoComment: '',
        principalInfoName: '',
        principalInfoMobile: '',
        principalInfoEmail: '',
        principalInfoEmergencyContact: '',
        principalInfoCertificateType: '',
        principalInfoCertificateNumber: '',
        principalInfoCertificateValidityDateStart: '',
        principalInfoCertificateValidatyDateEnd: '',
        principalInfoCertificatePhotoFront: '',
        principalInfoCertificatePhotoFrontImage: '',
        principalInfoCertificatePhotoBack: '',
        principalInfoCertificatePhotoBackImage: '',
        principalInfoAuthorizationLetter: '',
        principalInfoAuthorizationLetterImage: '',
        principalInfoVerifyTaskId: ''
      }
    })
    const icpMaterialsDialog = reactive({
      show: false,
      data: {
        commitmentLetter: '',
        commitmentLetterImage: '',
        businessNameChangeLetter: '',
        businessNameChangeLetterImage: '',
        partyBuildingConfirmationLetter: '',
        partyBuildingConfirmationLetterImage: '',
        promiseVideo: '',
        promiseVideoImage: '',
        authenticityResponsibilityLetter: '',
        authenticityResponsibilityLetterImage: '',
        authenticityCommitmentLetter: '',
        authenticityCommitmentLetterImage: '',
        websiteConstructionProposal: '',
        websiteConstructionProposalImage: '',
        subjectOtherMaterials: [],
        appletsOtherMaterials: [],
        holdingCertificatePhoto: '',
        holdingCertificatePhotoImage: ''
      }
    })
    const icpSubjectTypes = reactive([])
    const icpDistrictCodeList = reactive([])
    const icpCertificateTypes = reactive([])
    const icpServiceContentTypes = reactive([])
    const icpNrlxTypes = reactive([])
    const icpEntranceInfo = reactive({
      status: '',
      audit_data: []
    })
    const hints = reactive([])
    const getSettings = () => {
      axios('/v1/institution/settings')
        .then(res => {
          settings.appid = res.settings.appid
          settings.mchId = res.settings.mchId
          settings.partnerKey = res.settings.partnerKey
          settings.isPlatformPayment = res.settings.isPlatformPayment
          settings.hasCertFile = res.settings.hasCertFile
          settings.publishedThirdpartyTemplate = res.settings.publishedThirdpartyTemplate
          settings.experienceThirdpartyTemplate = res.settings.experienceThirdpartyTemplate
          settings.latestThirdpartyTemplate = res.settings.latestThirdpartyTemplate
          settings.signInTemplate = res.settings.signInTemplate
          settings.groupBuySuccessTemplate = res.settings.groupBuySuccessTemplate
          settings.groupBuyFailTemplate = res.settings.groupBuyFailTemplate
          settings.enrollSuccessTemplate = res.settings.enrollSuccessTemplate
          settings.enrollFailTemplate = res.settings.enrollFailTemplate
          settings.acceptPrizeTemplate = res.settings.acceptPrizeTemplate
          settings.accountBasicInfo = res.settings.accountBasicInfo
          settings.category = res.settings.category
          settings.style = res.settings.style
          settings.icpFiling = res.settings.icpFiling
          showAuth.value = false
          if (res.settings.icpFiling && res.settings.icpFiling.icpVerifyTaskId) {
            axios('/v1/institution/icp/verify/task/query', {
              icpVerifyTaskId: res.settings.icpFiling.icpVerifyTaskId
            })
              .then(res => {
                settings.icpFiling.verifyResult = res.faceStatus
              })
          }
          axios('/v1/institution/icp/entrance/info/query')
            .then(res => {
              icpEntranceInfo.status = res.info.status
              icpEntranceInfo.audit_data = res.info.audit_data
            })
          if (res.settings.experienceThirdpartyTemplate && res.settings.experienceThirdpartyTemplate.auditid) {
            // 重新查询状态
            axios('/v1/institution/thirdparty/template/status', {
              auditid: res.settings.experienceThirdpartyTemplate.auditid
            })
              .then(res => {
                settings.experienceThirdpartyTemplate.status = res.status
                settings.experienceThirdpartyTemplate.reason = res.reason
                settings.experienceThirdpartyTemplate.screenshot = res.screenshot
                if (res.status === 0) {
                  settings.experienceThirdpartyTemplate.statusMsg = '审核成功'
                } else if (res.status === 1) {
                  settings.experienceThirdpartyTemplate.statusMsg = '审核拒绝'
                } else if (res.status === 2) {
                  settings.experienceThirdpartyTemplate.statusMsg = '审核中'
                } else if (res.status === 3) {
                  settings.experienceThirdpartyTemplate.statusMsg = '已撤回'
                } else if (res.status === 4) {
                  settings.experienceThirdpartyTemplate.statusMsg = '审核延后'
                }
              })
          } else {
            // 检查是否有不在列表中的审核版本
            axios('/v1/institution/thirdparty/template/latest/audit/status', {
            })
              .then(res => {
                settings.latestAuditid = res.auditid
                settings.latestStatus = res.status
              })
          }
          if (res.settings.icpFiling) {
            if (res.settings.icpFiling.icpSubject) {
              icpSubjectDialog.data = JSON.parse(res.settings.icpFiling.icpSubject)
            }
            if (res.settings.icpFiling.icpApplets) {
              icpAppletsDialog.data = JSON.parse(res.settings.icpFiling.icpApplets)
            }
            if (res.settings.icpFiling.icpMaterials) {
              icpMaterialsDialog.data = JSON.parse(res.settings.icpFiling.icpMaterials)
            }
            axios('/v1/institution/icp/subject/types', {}).then(res => {
              res.icpSubjectTypes.map(icpSubjectType => icpSubjectTypes.push(icpSubjectType))
            })
            axios('/v1/institution/icp/district/code/list', {}).then(res => {
              res.icpDistrictCodeList.map(icpDistrictCode => icpDistrictCodeList.push(icpDistrictCode))
              if (icpSubjectDialog.data.baseInfoProvince) {
                cityList.length = 0
                icpDistrictCodeList.filter(icpDistrictCode => icpDistrictCode.code === icpSubjectDialog.data.baseInfoProvince)[0].sub_list.map(city => cityList.push(city))
              }
              if (icpSubjectDialog.data.baseInfoCity) {
                districtList.length = 0
                cityList.filter(city => city.code === icpSubjectDialog.data.baseInfoCity)[0].sub_list.map(district => districtList.push(district))
              }
            })
            axios('/v1/institution/icp/certificate/types', {}).then(res => {
              res.icpCertificateTypes.map(icpCertificateType => icpCertificateTypes.push(icpCertificateType))
            })
            axios('/v1/institution/icp/service/content/types', {}).then(res => {
              res.icpServiceContentTypes.map(icpServiceContentType => {
                icpServiceContentTypes.push(icpServiceContentType)
              })
            })
            axios('/v1/institution/icp/nrlx/types', {}).then(res => {
              res.icpNrlxTypes.map(icpNrlxType => icpNrlxTypes.push(icpNrlxType))
            })
          }
        })
    }
    const toAuth = () => {
      showAuth.value = true
      axios('/admin/thirdparty/open/pre/auth/code')
        .then(res => {
          const QRCode = require('qrcode')
          const canvas = document.getElementById('canvas')
          QRCode.toCanvas(canvas, `https://open.weixin.qq.com/wxaopen/safe/bindcomponent?action=bindcomponent&no_scan=1&component_appid=${res.componentAppid}&pre_auth_code=${res.preAuthCode}&redirect_uri=${res.redirectUri}&auth_type=2&biz_appid=#wechat_redirect`, () => {
          })
        })
    }
    const showMiniSettingDialog = () => {
      miniSettingDialog.data.mchId = settings.mchId
      miniSettingDialog.data.partnerKey = settings.partnerKey
      miniSettingDialog.data.isPlatformPayment = settings.isPlatformPayment ? '1' : '0'
      miniSettingDialog.show = true
    }
    const saveMiniSetting = () => {
      axios('/v1/institution/setting/mini/save', {
        ...miniSettingDialog.data
      })
        .then(res => {
          miniSettingDialog.show = false
          getSettings()
        })
    }
    const showNicknameDialog = () => {
      nicknameDialog.data.nickname = settings.accountBasicInfo.nickname
      nicknameDialog.show = true
    }
    const saveNickname = () => {
      axios('/v1/institution/thirdparty/nickname/save', {
        ...nicknameDialog.data
      })
        .then(res => {
          nicknameDialog.show = false
          getSettings()
        })
    }
    const checkNickname = () => {
      axios('/v1/institution/thirdparty/nickname/check', {
        ...nicknameDialog.data
      })
        .then(res => {
          checkNicknameDialog.show = true
          checkNicknameDialog.data.hitCondition = res.hitCondition
          checkNicknameDialog.data.wording = res.wording
        })
    }
    const showSignatureDialog = () => {
      signatureDialog.data.signature = settings.accountBasicInfo.signature_info.signature
      signatureDialog.show = true
    }
    const saveSignature = () => {
      axios('/v1/institution/thirdparty/signature/save', {
        ...signatureDialog.data
      })
        .then(res => {
          signatureDialog.show = false
          getSettings()
        })
    }
    const uploadHeadimg = ({ file }) => {
      const param = new FormData()
      param.append('file', file)
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
      axios('/v1/institution/thirdparty/headimg/upload', param, config)
        .then(() => {
          ElMessage({
            message: '已上传头像',
            type: 'success'
          })
          getSettings()
        })
    }
    const showStyleDialog = () => {
      styleDialog.data.style = settings.style
      styleDialog.show = true
    }
    const saveStyle = () => {
      axios('/v1/institution/style/save', {
        ...styleDialog.data
      })
        .then(res => {
          styleDialog.show = false
          getSettings()
        })
    }
    const saveThirdpartyMiniappMsgTemplate = () => {
      axios('/v1/institution/thirdparty/miniapp/msg/template/save', {
        ...thirdpartyMiniappMsgTemplateDialog.data
      })
        .then(res => {
          thirdpartyMiniappMsgTemplateDialog.show = false
          getSettings()
        })
    }
    const uploadImage = ({ file }) => {
      const param = new FormData()
      param.append('file', file)
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
      axios('/v1/institution/cert/file/upload', param, config)
        .then(() => {
          settings.hasCertFile = true
          ElMessage({
            message: '已上传证书',
            type: 'success'
          })
        })
    }
    const commitThirdpartyTemplate = (thirdpartyTemplateId) => {
      axios('/v1/institution/thirdparty/template/commit', {
        thirdpartyTemplateId
      })
        .then(() => {
          getSettings()
        })
    }
    const showQrcode = () => {
      axios('/v1/institution/experience/qrcode', {})
        .then(res => {
          qrcodeDialog.show = true
          qrcodeDialog.data.qrcode = res.qrcode
        })
    }
    getSettings()
    const submitAudit = () => {
      axios('/v1/institution/thirdparty/template/audit/submit')
        .then(res => {
          getSettings()
        })
    }
    const recallAudit = () => {
      axios('/v1/institution/thirdparty/template/audit/recall')
        .then(res => {
          getSettings()
        })
    }
    const speedupCodeAudit = () => {
      axios('/v1/institution/thirdparty/template/code/audit/speedup', {
        auditid: settings.experienceThirdpartyTemplate.auditid
      })
        .then(res => {
          getSettings()
        })
    }
    const publish = () => {
      axios('/v1/institution/thirdparty/template/audit/publish')
        .then(res => {
          getSettings()
        })
    }
    const showThirdpartyMiniappMsgTemplateDialog = (type) => {
      axios('/v1/institution/thirdparty/miniapp/msg/template', { type })
        .then(res => {
          thirdpartyMiniappMsgTemplateDialog.data = res.thirdpartyMiniappMsgTemplate
          thirdpartyMiniappMsgTemplateDialog.data.type = type
          thirdpartyMiniappMsgTemplateDialog.show = true
        })
    }
    const saveApplyMiniProgram = () => {
      axios('/v1/mini/program/apply', {
        ...applyMiniProgramDialog.data
      })
        .then(res => {
          applyMiniProgramDialog.show = false
        })
    }
    const showAddCategoryDialog = () => {
      if (categoryDialog.allCategories) {
        categoryDialog.show = true
        return
      }
      axios('/v1/institution/thirdparty/miniapp/categories/list')
        .then(res => {
          categoryDialog.show = true
          categoryDialog.allCategories = res.categories_list
          const firstCategories = []
          res.categories_list.categories.map(category => {
            if (category.level === 1) {
              firstCategories.push(category)
            }
          })
          categoryDialog.firstCategories = firstCategories
        })
    }
    const selectFirstCategory = () => {
      const secondCategories = []
      categoryDialog.allCategories.categories.map(category => {
        if (categoryDialog.selectedFirstCategoryId === category.father) {
          secondCategories.push(category)
        }
      })
      categoryDialog.secondCategories = secondCategories
      categoryDialog.selectedSecondCategoryId = ''
      categoryDialog.selectedCategory = null
    }
    const selectSecondCategory = () => {
      categoryDialog.allCategories.categories.map(category => {
        if (categoryDialog.selectedSecondCategoryId === category.id) {
          categoryDialog.selectedCategory = category
        }
      })
    }
    const startUpload = (innerName) => {
      categoryDialog.innerName = innerName
    }
    const uploadCertificate = ({ file }) => {
      const param = new FormData()
      param.append('file', file)
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
      axios('/v1/institution/thirdparty/certificate/upload', param, config)
        .then((res) => {
          ElMessage({
            message: '已上传证明文件',
            type: 'success'
          })
          const index = categoryDialog.certificateFileList.findIndex(certificateFile => certificateFile.innerName === categoryDialog.innerName)
          if (index >= 0) {
            categoryDialog.certificateFileList[index] = {
              innerName: categoryDialog.innerName,
              value: res.mediaId
            }
          } else {
            categoryDialog.certificateFileList.push({
              innerName: categoryDialog.innerName,
              value: res.mediaId
            })
          }
        })
    }
    const saveCategory = () => {
      const certicates = []
      let errorTip = ''
      categoryDialog.selectedCategory.qualify.exter_list.map(exter => {
        exter.inner_list.map(inner => {
          const index = categoryDialog.certificateFileList.findIndex(certificateFile => certificateFile.innerName === inner.name && certificateFile.value)
          if (index < 0 || !categoryDialog.certificateFileList.length) {
            errorTip = '请上传资质文件'
            return
          }
          certicates.push({
            key: inner.name,
            value: categoryDialog.certificateFileList[index].value
          })
        })
      })
      if (errorTip) {
        ElMessage({
          message: errorTip,
          type: 'fail'
        })
        return
      }
      axios('/v1/institution/thirdparty/category/save', {
        first: categoryDialog.selectedFirstCategoryId,
        second: categoryDialog.selectedSecondCategoryId,
        certicates
      })
        .then(res => {
          categoryDialog.show = false
          getSettings()
        })
    }
    const delCategory = (first, second) => {
      window.$confirm('是否确认删除').then(() => {
        axios('/v1/institution/thirdparty/category/del', {
          first,
          second
        })
          .then(() => {
            getSettings()
          })
      }).catch(_ => { })
    }
    const addVerifyTask = () => {
      axios('/v1/institution/icp/verify/task/add', {}).then(() => {
        getSettings()
      })
    }
    const showInputIcpSubject = () => {
      icpSubjectDialog.show = true
    }
    const showInputIcpApplets = () => {
      icpAppletsDialog.show = true
    }
    const showInputIcpMaterials = () => {
      icpMaterialsDialog.show = true
    }
    const cityList = reactive([])
    const districtList = reactive([])
    const selectProvince = () => {
      cityList.length = 0
      districtList.length = 0
      icpSubjectDialog.data.baseInfoCity = ''
      icpSubjectDialog.data.baseInfoDistrict = ''
      icpDistrictCodeList.filter(icpDistrictCode => icpDistrictCode.code === icpSubjectDialog.data.baseInfoProvince)[0].sub_list.map(city => cityList.push(city))
    }
    const selectCity = () => {
      districtList.length = 0
      icpSubjectDialog.data.baseInfoDistrict = ''
      cityList.filter(city => city.code === icpSubjectDialog.data.baseInfoCity)[0].sub_list.map(district => districtList.push(district))
    }
    const uploadIcpMedia = ({ file, data }) => {
      upload(file, '0', (url) => {
        const param = new FormData()
        param.append('certificateType', data.certificateType)
        param.append('icpOrderField', data.icpOrderField)
        param.append('file', file)
        const config = {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
        axios('/v1/institution/icp/media/upload', param, config)
          .then(res => {
            switch (data.icpOrderField) {
              case 'icp_subject.personal_info.residence_permit':
                icpSubjectDialog.data.personalInfoResidencePermit = res.mediaId
                icpSubjectDialog.data.personalInfoResidencePermitImage = url
                break
              case 'icp_subject.organize_info.certificate_photo':
                icpSubjectDialog.data.organizeInfoCertificatePhoto = res.mediaId
                icpSubjectDialog.data.organizeInfoCertificatePhotoImage = url
                break
              case 'icp_subject.principal_info.certificate_photo_front':
                icpSubjectDialog.data.principalInfoCertificatePhotoFront = res.mediaId
                icpSubjectDialog.data.principalInfoCertificatePhotoFrontImage = url
                break
              case 'icp_subject.principal_info.certificate_photo_back':
                icpSubjectDialog.data.principalInfoCertificatePhotoBack = res.mediaId
                icpSubjectDialog.data.principalInfoCertificatePhotoBackImage = url
                break
              case 'icp_subject.principal_info.authorization_letter':
                icpSubjectDialog.data.principalInfoAuthorizationLetter = res.mediaId
                icpSubjectDialog.data.principalInfoAuthorizationLetterImage = url
                break
              case 'icp_applets.principal_info.certificate_photo_front':
                icpAppletsDialog.data.principalInfoCertificatePhotoFront = res.mediaId
                icpAppletsDialog.data.principalInfoCertificatePhotoFrontImage = url
                break
              case 'icp_applets.principal_info.certificate_photo_back':
                icpAppletsDialog.data.principalInfoCertificatePhotoBack = res.mediaId
                icpAppletsDialog.data.principalInfoCertificatePhotoBackImage = url
                break
              case 'icp_applets.principal_info.authorization_letter':
                icpAppletsDialog.data.principalInfoAuthorizationLetter = res.mediaId
                icpAppletsDialog.data.principalInfoAuthorizationLetterImage = url
                break
              case 'icp_applets.base_info.nrlx_details':
                nrlxTypeDialog.data.media = res.mediaId
                nrlxTypeDialog.data.image = url
                break
              case 'icp_materials.commitment_letter':
                icpMaterialsDialog.data.commitmentLetter = res.mediaId
                icpMaterialsDialog.data.commitmentLetterImage = url
                break
              case 'icp_materials.business_name_change_letter':
                icpMaterialsDialog.data.businessNameChangeLetter = res.mediaId
                icpMaterialsDialog.data.businessNameChangeLetterImage = url
                break
              case 'icp_materials.party_building_confirmation_letter':
                icpMaterialsDialog.data.partyBuildingConfirmationLetter = res.mediaId
                icpMaterialsDialog.data.partyBuildingConfirmationLetterImage = url
                break
              case 'icp_materials.promise_video':
                icpMaterialsDialog.data.promiseVideo = res.mediaId
                icpMaterialsDialog.data.promiseVideoImage = url
                break
              case 'icp_materials.authenticity_responsibility_letter':
                icpMaterialsDialog.data.authenticityResponsibilityLetter = res.mediaId
                icpMaterialsDialog.data.authenticityResponsibilityLetterImage = url
                break
              case 'icp_materials.authenticity_commitment_letter':
                icpMaterialsDialog.data.authenticityCommitmentLetter = res.mediaId
                icpMaterialsDialog.data.authenticityCommitmentLetterImage = url
                break
              case 'icp_materials.website_construction_proposal':
                icpMaterialsDialog.data.websiteConstructionProposal = res.mediaId
                icpMaterialsDialog.data.websiteConstructionProposalImage = url
                break
              case 'icp_materials.subject_other_materials':
                icpMaterialsDialog.data.subjectOtherMaterials.push({ mediaId: res.mediaId, url })
                break
              case 'icp_materials.applets_other_materials':
                icpMaterialsDialog.data.appletsOtherMaterials.push({ mediaId: res.mediaId, url })
                break
              case 'icp_materials.holding_certificate_photo':
                icpMaterialsDialog.data.holdingCertificatePhoto = res.mediaId
                icpMaterialsDialog.data.holdingCertificatePhotoImage = url
                break
            }
            axios('/v1/institution/icp/filing/save', {
              icpSubject: JSON.stringify(icpSubjectDialog.data),
              icpApplets: JSON.stringify(icpAppletsDialog.data),
              icpMaterials: JSON.stringify(icpMaterialsDialog.data)
            })
          })
      })
    }
    const saveIcpSubject = () => {
      axios('/v1/institution/icp/filing/save', {
        icpSubject: JSON.stringify(icpSubjectDialog.data)
      })
        .then(() => {
          icpSubjectDialog.show = false
        })
    }
    const saveIcpApplets = () => {
      axios('/v1/institution/icp/filing/save', {
        icpApplets: JSON.stringify(icpAppletsDialog.data)
      })
        .then(() => {
          icpAppletsDialog.show = false
        })
    }
    const saveIcpMaterials = () => {
      axios('/v1/institution/icp/filing/save', {
        icpMaterials: JSON.stringify(icpMaterialsDialog.data)
      })
        .then(() => {
          icpMaterialsDialog.show = false
        })
    }
    const serviceContentTypeDialog = reactive({
      show: false,
      data: {
        parentServiceContentType: '',
        serviceContentType: ''
      }
    })
    const showServiceContentTypeDialog = () => {
      icpAppletsDialog.show = false
      serviceContentTypeDialog.data.parentServiceContentType = ''
      serviceContentTypeDialog.data.serviceContentType = ''
      serviceContentTypeDialog.show = true
    }
    const hideServiceContentTypeDialog = () => {
      icpAppletsDialog.show = true
      serviceContentTypeDialog.show = false
    }
    const saveServiceContentType = () => {
      serviceContentTypeDialog.show = false
      icpAppletsDialog.show = true
      icpAppletsDialog.data.baseInfoServiceContentTypes.push({ type: serviceContentTypeDialog.data.serviceContentType, name: icpServiceContentTypes.filter(icpServiceContentType => icpServiceContentType.type === serviceContentTypeDialog.data.serviceContentType)[0].name })
    }
    const removeBaseInfoServiceContentType = (type) => {
      const index = icpAppletsDialog.data.baseInfoServiceContentTypes.findIndex(baseInfoServiceContentType => baseInfoServiceContentType.type === type)
      icpAppletsDialog.data.baseInfoServiceContentTypes.splice(index, 1)
    }
    const nrlxTypeDialog = reactive({
      show: false,
      data: {
        type: '',
        code: '',
        media: '',
        image: ''
      }
    })
    const showNrlxTypeDialog = () => {
      icpAppletsDialog.show = false
      nrlxTypeDialog.data.type = ''
      nrlxTypeDialog.data.code = ''
      nrlxTypeDialog.data.media = ''
      nrlxTypeDialog.data.image = ''
      nrlxTypeDialog.show = true
    }
    const hideNrlxTypeDialog = () => {
      icpAppletsDialog.show = true
      nrlxTypeDialog.show = false
    }
    const saveNrlxType = () => {
      nrlxTypeDialog.show = false
      icpAppletsDialog.show = true
      icpAppletsDialog.data.baseInfoNrlxDetails.push({ type: nrlxTypeDialog.data.type, name: icpNrlxTypes.filter(icpNrlxType => icpNrlxType.type === nrlxTypeDialog.data.type)[0].name, code: nrlxTypeDialog.data.code, media: nrlxTypeDialog.data.media, image: nrlxTypeDialog.data.image })
    }
    const removeBaseInfoNrlxDetail = (type) => {
      const index = icpAppletsDialog.data.baseInfoNrlxDetails.findIndex(baseInfoNrlxDetail => baseInfoNrlxDetail.type === type)
      icpAppletsDialog.data.baseInfoNrlxDetails.splice(index, 1)
    }
    const removeSubjectOtherMaterial = (url) => {
      const index = icpMaterialsDialog.data.subjectOtherMaterials.findIndex(subjectOtherMaterial => subjectOtherMaterial.url === url)
      icpMaterialsDialog.data.subjectOtherMaterials.splice(index, 1)
    }
    const removeAppletsOtherMaterial = (url) => {
      const index = icpMaterialsDialog.data.appletsOtherMaterials.findIndex(appletsOtherMaterial => appletsOtherMaterial.url === url)
      icpMaterialsDialog.data.appletsOtherMaterials.splice(index, 1)
    }
    const submitIcpFiling = () => {
      axios('/v1/institution/icp/filing/apply')
        .then(res => {
          if (res.result.errcode === 0) {
            window.$message({
              message: '提交备案成功',
              type: 'success'
            })
            return
          }
          hints.length = 0
          res.result.hints.map(hint => {
            hints.push(hint)
          })
        })
    }
    return {
      settings,
      toAuth,
      getSettings,
      showAuth,
      miniSettingDialog,
      nicknameDialog,
      signatureDialog,
      checkNicknameDialog,
      styleDialog,
      thirdpartyMiniappMsgTemplateDialog,
      saveMiniSetting,
      saveThirdpartyMiniappMsgTemplate,
      uploadHeadimg,
      uploadImage,
      showMiniSettingDialog,
      categoryDialog,
      commitThirdpartyTemplate,
      showQrcode,
      qrcodeDialog,
      submitAudit,
      recallAudit,
      speedupCodeAudit,
      publish,
      showThirdpartyMiniappMsgTemplateDialog,
      showStyleDialog,
      saveStyle,
      applyMiniProgramDialog,
      saveApplyMiniProgram,
      saveNickname,
      checkNickname,
      saveSignature,
      showNicknameDialog,
      showSignatureDialog,
      showAddCategoryDialog,
      selectFirstCategory,
      selectSecondCategory,
      startUpload,
      uploadCertificate,
      saveCategory,
      delCategory,
      addVerifyTask,
      showInputIcpSubject,
      showInputIcpApplets,
      showInputIcpMaterials,
      icpSubjectDialog,
      icpSubjectTypes,
      icpDistrictCodeList,
      icpCertificateTypes,
      icpServiceContentTypes,
      icpNrlxTypes,
      selectProvince,
      cityList,
      selectCity,
      districtList,
      uploadIcpMedia,
      icpAppletsDialog,
      icpMaterialsDialog,
      saveIcpSubject,
      saveIcpApplets,
      saveIcpMaterials,
      mediaArray,
      serviceContentTypeDialog,
      showServiceContentTypeDialog,
      hideServiceContentTypeDialog,
      saveServiceContentType,
      removeBaseInfoServiceContentType,
      showNrlxTypeDialog,
      nrlxTypeDialog,
      hideNrlxTypeDialog,
      saveNrlxType,
      removeBaseInfoNrlxDetail,
      removeSubjectOtherMaterial,
      removeAppletsOtherMaterial,
      submitIcpFiling,
      icpEntranceInfo,
      hints
    }
  }
}
</script>
<style lang="scss" scoped>
.el-descriptions {
  padding: 20px;
}
.dialog-footer button:first-child {
  margin-right: 10px;
}
.canvas {
  text-align: center;
}
.main {
  margin: 15px;
  background-color: #fff;
  border-radius: 3px;
  height: calc(100% - 30px);
  width: calc(100% - 30px);
}
</style>
